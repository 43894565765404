import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Contact,
  useEditContactBirthdayMutation,
  useGetContactBirthdayItemQuery
} from 'integration/resources/wallet'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import * as Yup from 'yup'

import {
  ContactBirthdayFormProps,
  UseContactEditBirthdayScreen
} from './ContactEditBirthdayScreen.types'

const schema = Yup.object().shape({
  birthday_message: Yup.string()
    .nullable()
    .max(90, 'Mensagem pode ter no máximo 90 caracteres.')
    .required('Informe uma mensagem'),
  birthday_message_title: Yup.string()
    .nullable()
    .min(3, 'Título precisa ter no mínimo 3 caracteres')
    .max(30, 'Título pode ter no máximo 30 caracteres')
    .required('Informe um título')
})

export const useContactEditBirthdayScreen: UseContactEditBirthdayScreen = ({
  navigation,
  route
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { contactId } = route.params

  const { data } = useGetContactBirthdayItemQuery(contactId)

  const contact = data?.data.data

  const { control, handleSubmit, setValue, reset, watch } = useForm<Contact>({
    resolver: yupResolver(schema)
  })

  const isProgrammedMessage = watch('is_programmed_message')

  const { isLoading, mutate } = useEditContactBirthdayMutation(contactId)

  const handleGoToContactBirthDate = useCallback(() => {
    navigation.navigate('ContactBirthday', { contactId })
  }, [navigation, contactId])

  const submit = handleSubmit(
    useCallback<SubmitHandler<Contact>>(
      async (formData) => {
        Keyboard.dismiss()

        const { jobTitle, tab_number = '0' } = formData

        const formattedFields = {
          ...formData,
          job_title: jobTitle?.toUpperCase(),
          store_tab_number: parseInt(tab_number, 10)
        } as Contact

        mutate(formattedFields, {
          onSuccess: handleGoToContactBirthDate
        })
      },
      [mutate, handleGoToContactBirthDate]
    )
  )

  const handleToggle = (name: ContactBirthdayFormProps, value: boolean) => setValue(name, value)

  useEffect(() => {
    if (contact) {
      reset({
        ...contact,
        message_send_date:
          contact.message_send_date ??
          new Date(
            new Date().getFullYear(),
            contact.birthDatePicker?.month
              ? Number(contact.birthDatePicker?.month) - 1
              : new Date().getMonth(),
            Number(contact.birthDatePicker?.day),
            0,
            10
          ).toISOString()
      })
    }
  }, [reset, contact])

  return {
    isMobile,
    control,
    isLoading,
    submit,
    handleToggle,
    contact,
    isProgrammedMessage: isProgrammedMessage ?? false
  }
}
